import '../scripts/common';
import Vue from 'vue';
import Helpers from '../scripts/helpers';
import 'core-js/modules/es.promise';
import 'core-js/modules/es.array.iterator';
import 'whatwg-fetch';
import 'lazysizes/plugins/object-fit/ls.object-fit';
import 'lazysizes';
import 'lazysizes/plugins/parent-fit/ls.parent-fit';
import 'lazysizes/plugins/respimg/ls.respimg';
import store from './store/store';
import Services from '../scripts/services/services';
import BootstrapVue from 'bootstrap-vue';
import { FontAwesomeIcon } from '@fortawesome/vue-fontawesome';
import './fontawesome';
import { capitalize, formatSize, integer, lowercase, truncate } from '../scripts/filters';
import * as moment from 'moment';
import { mapGetters } from 'vuex';
import filters from './filters/index';

Vue.use(BootstrapVue);

//Font Awesome Icons
Vue.component('fa-icon', FontAwesomeIcon);

//Filters
Vue.filter('capitalize', capitalize);
Vue.filter('lowercase', lowercase);
Vue.filter('truncate', truncate);
Vue.filter('formatSize', formatSize);
Vue.filter('integer', integer);

// Boot the current Vue component
const root = document.getElementById('app');

store.commit('layout', JSON.parse(root.dataset.layout));
store.commit('env', JSON.parse(root.dataset.env));
store.commit('title', root.dataset.title);
store.commit('routes', JSON.parse(root.dataset.routes));

Services.instance().register('store', () => {
    return store;
});

Services.instance().register('event', () => {
    return new Vue();
});

moment.locale('ru');
Vue.mixin({
    methods: {
        preparePrice(number, decimals, dec_point, thousands_sep) {
            return Helpers.preparePrice(number, decimals, dec_point, thousands_sep);
        },
        preparePhone(str) {
            //Filter only numbers from the input
            let cleaned = ('' + str).replace(/\D/g, '');

            //Check if the input is of correct length
            let match = cleaned.match(/^(\d)(\d{3})(\d{3})(\d{2})(\d{2})$/);

            if (match) {
                return '+' + match[1] + '(' + match[2] + ') ' + match[3] + '-' + match[4] + '-' + match[5];
            }

            return '';
        },
        pluralForm(n, forms) {
            return Helpers.plural_form(n, forms);
        },
        strToList(str) {
            if (!str) {
                return [];
            }
            return String(str).split('\n');
        },
        route(name) {
            return '/' + this.$store.state.routes[name].replace(/^\//, '');
        },
        datePrint(date) {
            return moment(date, "YYYY-MM-DD").format('LL');
        },
        datetimePrint(date) {
            return moment(date, "YYYY-MM-DD HH:mm:ss").format('LLL');
        },
        printDoc(src) {
            let iframe = this._printIframe;
            if (!this._printIframe) {
                iframe = this._printIframe = document.createElement('iframe');
                document.body.appendChild(iframe);

                iframe.style.display = 'none';
                iframe.onload = function() {
                    setTimeout(function() {
                        iframe.focus();
                        iframe.contentWindow.print();
                    }, 1);
                };
            }

            iframe.src = src;
        },
    },
    computed: {
        ...mapGetters(['getRoute']),
        staticText() {
            return this.$store.state.layout.staticBlock;
        },
        isGuest() {
            return this.$store.state.layout.isGuest;
        },
        /** @return {DeliveryType} */
        discountTypes() {
            return this.$store.state.layout.discountTypes;
        },
    },
});

/**
 @typedef DeliveryType
 @type {Object}
 @property {integer} offer
 @property {integer} bundleOffer
 @property {integer} bundleMasterclass
 @property {integer} brand
 @property {integer} category
 @property {integer} masterclass
 @property {integer} delivery
 @property {integer} cartTotal
 @property {integer} anyOffer
 @property {integer} anyBundle
 @property {integer} anyBrand
 @property {integer} anyCategory
 @property {integer} anyMasterclass
 */
