import Vue from 'vue'

const toTwoDigits = num => {
    return num.toString().padStart(2, '0')
}

export const beautyTimestamp = timestamp => {
    //from 18:40:00 2020-09-14
    let datetime = timestamp.split(' ')
    let time = datetime[0].split(':')
    let date = datetime[1].split('-')

    let year = date[0]
    let mounth = date[1]
    let day = date[2]

    let hours = time[0]
    let minutes = time[1]
    //let seconds = time[2]

    //to 16.11.2019, 12:27
    return `${day}.${mounth}.${year}, ${hours}:${minutes}`
}

export const beautyTimestamp2 = timestamp => {
    //from 18:40:00 2020-09-14
    let datetime = timestamp.split(' ')
    let time = datetime[1].split(':')
    let date = datetime[0].split('-')

    let year = date[0]
    let mounth = date[1]
    let day = date[2]

    let hours = time[0]
    let minutes = time[1]
    //let seconds = time[2]

    //to 16.11.2019, 12:27
    return `${day}.${mounth}.${year}, ${hours}:${minutes}`
}

export const beautyTimestamp3 = (timestamp, dateOnly = false) => {
    // from 2023-03-08T09:00:00.000000Z
    const date = new Date(timestamp)
    const yyyy = date.getFullYear()
    const mm = toTwoDigits(date.getMonth() + 1)
    const dd = toTwoDigits(date.getDate())
    if (!dateOnly) {
        const HH = toTwoDigits(date.getHours())
        const MM = toTwoDigits(date.getMinutes())
        // to 08.03.2023, 12:00
        return `${dd}.${mm}.${yyyy}, ${HH}:${MM}`
    }

    // to 08.03.2023
    return `${dd}.${mm}.${yyyy}`
}

export const beautyTime1 = (time) => {
    // from 12:00:00 to 12:00
    const digits = time.split(':')
    if (digits.length === 1) {
        return digits[0]
    }
    return digits[0] + ':' + digits[1]
}

Vue.filter('dateTime', beautyTimestamp)
Vue.filter('dateTime2', beautyTimestamp2)
Vue.filter('dateTime3', beautyTimestamp3)
Vue.filter('time1', beautyTime1)

export default beautyTimestamp