var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c("nav", { staticClass: "sidebar" }, [
    _c("div", { staticClass: "mobile" }, [
      _c(
        "div",
        { staticClass: "menu" },
        _vm._l(_vm.menu, function (item, index) {
          return _c("menu-item", { key: index, attrs: { item: item } })
        }),
        1
      ),
      _vm._v(" "),
      _c("div", { staticClass: "footer" }, [
        _c(
          "div",
          { staticClass: "tools" },
          [
            _c("sing-out", { attrs: { label: true, colorInverse: true } }),
            _vm._v(" "),
            _c("notifications", { attrs: { label: true, colorInverse: true } }),
            _vm._v(" "),
            _c("mail", { attrs: { label: true, colorInverse: true } }),
          ],
          1
        ),
        _vm._v(" "),
        _c(
          "div",
          { staticClass: "manager" },
          [_c("manager", { attrs: { mobile: true, colorInverse: true } })],
          1
        ),
      ]),
    ]),
    _vm._v(" "),
    _c(
      "div",
      { staticClass: "desktop" },
      _vm._l(_vm.menu, function (item, index) {
        return _c("HorizontalMenuItem", { key: index, attrs: { item: item } })
      }),
      1
    ),
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }