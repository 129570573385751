<template>
    <div class="layout">
        <div class="fake-vue-body" id="g-mas-pageContent">
            <LayoutHeader :on-index="onIndex"></LayoutHeader>
            <div class="d-flex flex-row middle-area">
                <div class="container-fluid flex-grow-1 pb-10 pt-3 pl-4 pr-4">
                    <div v-if="back" class="mt-3">
                        <span role="button" @click="goBack"><fa-icon icon="angle-left"></fa-icon> Назад</span>
                    </div>
                    <div class="pageTitle mt-4 mb-4">
                        <slot name="title">
                            <h1>{{ title }}</h1>
                        </slot>
                    </div>
                    <slot></slot>
                </div>
            </div>

            <modal-message></modal-message>
            

            <div id="preloader" v-show="loaderShow"><div id="loader"></div></div>
        </div>
        <div class="footer">
            <LayoutFooter></LayoutFooter>
        </div>
    </div>
</template>

<script>
    import LayoutFooter from '../layout-footer/layout-footer.vue';
    import LayoutHeader from '../layout-header/layout-header.vue';
    import ModalMessage from '../modal-message/modal-message.vue';
    import MainMenu from '../main-menu/main-menu.vue';
    import Services from '../../../scripts/services/services.js';

    export default {
    name: 'layout-main',
    props: {
        onIndex: { type: Boolean, default: false },
        back: Boolean,
        backUrl: String,
        customTitle: {
            type: String,
            default: ''
        }
    },
    components: {
        LayoutFooter,
        LayoutHeader,
        ModalMessage,
        MainMenu,
    },
    methods: {
        goBack() {
            if (this.backUrl) {
                if (document.referrer) {
                    const referrer = new URL(document.referrer);
                    window.location.href = (
                        (referrer.host === location.host) &&
                        (referrer.pathname === this.backUrl)
                    )
                        ? referrer
                        : this.backUrl;
                } else {
                    window.location.href = this.backUrl;
                }
            } else {
                if (document.referrer) {
                    const referrer = new URL(document.referrer);
                    if (referrer.host === location.host) {
                        window.location.href = referrer;
                    }
                }
            }
        }
    },
    computed: {
        title() {
            return this.customTitle === '' ?  this.$store.state.title : this.customTitle;
        },
        loaderShow() {
            return Services.store().state.loaderShow;
        },
    },
    created() {
        Services.event().$on('toast', ({text, variant}) => {
            this.$bvToast.toast(text, {
                title: 'Сообщение',
                variant: variant || 'success',
                //toaster: 'b-toaster-top-center',
                solid: true,
                autoHideDelay: 5000,
            });
        });
    }
};
</script>

<style scoped>

    .layout
    {
        position: relative;
        height: 100%;
    }

    .fake-vue-body {
        position: relative;
        min-height: 100%;
        background: #fff;
        overflow-x: hidden;
       
    }
    .middle-area {
        background: #fff;
        padding-bottom: 60px;
        
    }

    .pageTitle h1, .pageTitle > *
    {
        font-size: 38px;
        font-weight: 500;
    }

    @media screen and (max-width: 1023px) {
        .pageTitle
        {
            margin-top: 1rem!important;
            margin-bottom: 1rem!important;
            font-size: 22px;
        }
        .pageTitle h1, .pageTitle > *
        {
            font-size: 22px;
        }
    }
    

    /* ============= Loader ===================== */
    #preloader {
        position: fixed;
        top: 0;
        left: 0;
        width: 100%;
        height: 100%;
        z-index: 10000;
        background: rgba(255, 255, 255, 0.6);
    }
    #loader {
        display: block;
        position: relative;
        left: 50%;
        top: 50%;
        width: 150px;
        height: 150px;
        margin: -75px 0 0 -75px;
        border-radius: 50%;
        border: 3px solid transparent;
        border-top-color: #000000;
        -webkit-animation: spin 2s linear infinite;
        animation: spin 2s linear infinite;
    }
    #loader:before {
        content: "";
        position: absolute;
        top: 5px;
        left: 5px;
        right: 5px;
        bottom: 5px;
        border-radius: 50%;
        border: 3px solid transparent;
        border-top-color: #000000;
        -webkit-animation: spin 3s linear infinite;
        animation: spin 3s linear infinite;
    }
    #loader:after {
        content: "";
        position: absolute;
        top: 15px;
        left: 15px;
        right: 15px;
        bottom: 15px;
        border-radius: 50%;
        border: 3px solid transparent;
        border-top-color: #000000;
        -webkit-animation: spin 1.5s linear infinite;
        animation: spin 1.5s linear infinite;
    }
    @-webkit-keyframes spin {
        0%   {
            -webkit-transform: rotate(0deg);
            -ms-transform: rotate(0deg);
            transform: rotate(0deg);
        }
        100% {
            -webkit-transform: rotate(360deg);
            -ms-transform: rotate(360deg);
            transform: rotate(360deg);
        }
    }
    @keyframes spin {
        0%   {
            -webkit-transform: rotate(0deg);
            -ms-transform: rotate(0deg);
            transform: rotate(0deg);
        }
        100% {
            -webkit-transform: rotate(360deg);
            -ms-transform: rotate(360deg);
            transform: rotate(360deg);
        }
    }

    .middle-area
    {
        margin-bottom: 96px;
    }

    .footer {
        position: relative;
        z-index: 999;
        margin-top: -96px;

    }

    @media screen and (max-width: 1023px) 
    {
        .middle-area
        {
            margin-bottom: 182px;
        }

        .footer {
            margin-top: -182px;
        }

    }
</style>
