<template>
    <footer v-if="!isGuest" class="mas-footer">
        <div>
            <div class="d-flex flex-column">
                <manager  class="only-desktop" />
                <manager class="only-mobile mobile" :mobile="true"/>
            </div>
        </div>
        <div class="link">
            <span><a href="/user/merchant_instruction_ryvok_v3.docx" target="_blank">Инструкция по работе с личным кабинетом</a></span>
        </div>
    </footer>
</template>

<script>


import Manager from '../account-tools/manager.vue'

export default {
    name: 'vue-footer',
    components: { Manager },
    data() {
        return {};
    },

};
</script>
<style scoped>
    .mas-footer {
        display: flex;
        background-color: #141116;
        height: 96px;
        padding-bottom: 21px;
        color: #fff;
        font-size: 14px;
        /* line-height: 16px;    */
        align-items: flex-end; 
        justify-content: space-between;
    }

    .mas-footer a, .mas-footer a:hover
    {
        color: #fff;
    }

    .link {
        padding-right: 32px;
    }

    .only-mobile
    {
        display: none;
    }

    .only-desktop
    {
        display: block;
    }   

    @media screen and (max-width: 1023px) 
    {
            .mas-footer 
            {
                display: block;
                height: auto;
                padding: 24px 0 24px 0;

            }

            .link {
                display: block;
                margin-top: 24px;
                text-align: center;
                padding-right: 0px;
                font-size: 14px;
            }


            .only-mobile
            {
                display: block;
            }

            .only-desktop
            {
                display: none;
            }      
    }


</style>
